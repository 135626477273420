<template>
  <div id="Banner">
    <CommonManageMain>
      <template v-slot:header>
        <div>
          <el-button type="primary"
                     size="small"
                     icon="el-icon-circle-plus-outline"
                     @click="clickAddRole()">增加
          </el-button>
        </div>

        <CommonForm :form="searchForm"
                    :formLabel="searchFormLabel"
                    inline>
          <el-button type="primary"
                     @click="searchKeyword(searchForm)">搜索</el-button>
          <el-button type="text"
                     @click="resetFilter(searchForm)">重置筛选条件</el-button>
        </CommonForm>
      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTable :tableData="tableData"
                     :tableLabel="tableLabel"
                     :tableConfig="tableConfig"
                     @getPage="getPage"
                     style="height:600px">
          <el-table-column label="操作"
                           width="180">
            <template slot-scope="scope">
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="编辑"
                          placement="top">
                <span class="button"
                      @click="clickEditUser(scope.row)">编辑</span>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="关联权限"
                          placement="top">
                <span class="button"
                      @click="clickEditRole(scope.row)">关联权限</span>
              </el-tooltip>
            </template>
          </el-table-column>
        </CommonTable>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import CommonManageMain from 'components/CommonManageMain.vue'

export default {
  components: {
    CommonManageMain
  },
  data () {
    return {
      // form表单 搜索数据
      searchForm: {
        keyword: ''
      },
      searchFormLabel: [
        {
          model: 'keyword',
          label: '海报名称',
        }
      ],
    }
  }
}
</script>


<style lang="scss" scoped>
</style>